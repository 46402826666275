import oneDigitNumberToTwoDigitString from "../../utils/oneDigitNumberToTwoDigitString";
import {Timeline, Button, Badge} from 'flowbite-react/lib/cjs/components';
import {HiOutlineFolder} from "react-icons/hi";

const TaskList = ({tasks, projectIdsToProjects, tagIdsToTags, addToProjectFilters, addToTagFilters}) => {
    return (
        <Timeline>
            {tasks.map(task => (
                <Timeline.Item key={task.id}>
                    <Timeline.Point/>
                    <Timeline.Content>

                        {/* Task Start Date and Time*/}
                        <Timeline.Time>
                            {task.startDate.day}/{task.startDate.month}/{task.startDate.year} - {task.startTime.hours}:{oneDigitNumberToTwoDigitString(task.startTime.minutes)}:{oneDigitNumberToTwoDigitString(task.startTime.seconds)}
                        </Timeline.Time>

                        {/* Task Project*/}
                        <div className="flex flex-wrap gap-2">
                            {task.projectId !== undefined && projectIdsToProjects(task.projectId).length > 0 &&
                                <Button size={"xs"}
                                        onClick={() => addToProjectFilters(task.projectId)}
                                >
                                    <HiOutlineFolder className="mr-1 h-4 w-4"/>
                                    {projectIdsToProjects(task.projectId)[0].name}
                                </Button>
                            }
                        </div>

                        {/* Task Name*/}
                        <Timeline.Title>
                            {task.name}
                        </Timeline.Title>

                        {/* Task Duration*/}
                        <Timeline.Body>
                            Duration: {task.duration.hours + ":" + oneDigitNumberToTwoDigitString(task.duration.minutes) + ":" + oneDigitNumberToTwoDigitString(task.duration.seconds)}
                        </Timeline.Body>

                        {/* Task Tags */}
                        <div className="flex flex-wrap gap-2">
                            {tagIdsToTags(task.tagIds).map(tag =>
                                <Badge key={tag.id} color="info"
                                       onClick={() => addToTagFilters(tag.id)}
                                >
                                    # {tag.name}
                                </Badge>
                            )}
                        </div>

                    </Timeline.Content>
                </Timeline.Item>
            ))}
        </Timeline>
    );
}

export default TaskList