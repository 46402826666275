import {Fragment, useContext, useEffect, useState} from "react";
import DataContext from "../dataStorage/DataProvider";
import TaskList from "./tasks/TaskList";
import TaskFilters from "./tasks/TaskFilters";
import {HiOutlineFolder, HiOutlineTrash, HiXCircle, HiOutlineExclamationCircle} from "react-icons/hi";
import {Button, Modal} from 'flowbite-react/lib/cjs/components';


const Tasks = () => {
    const {tasks, setTasks, projects, tags} = useContext(DataContext);
    const [filters, setFilters] = useState({})
    const [filteredTasks, setFilteredTasks] = useState([])
    const [deleteTasksPopupEnabled, setDeleteTasksPopupEnabled] = useState(false)
    useEffect(() => {
        setFilteredTasks(tasks.filter(task => {
            if (filters.projects !== undefined && filters.projects.length > 0 && (
                task.projectId === undefined || task.projectId === "" || filters.projects.indexOf(task.projectId) === -1
            )) return false;

            if (filters.tags !== undefined && filters.tags.length > 0 && (
                task.tagIds === undefined || task.tagIds.length === 0 || filters.tags.filter(tag => task.tagIds.indexOf(tag) === -1).length > 0
            )) return false;

            return true
        }))
    }, [tasks, filters])

    const projectIdsToProjects = (ids) => {
        const projectList = projects.filter(project => ids.indexOf(project.id) > -1);
        if (projectList.length > 0)
            return projectList;
        return [];
    }

    const tagIdsToTags = (ids) => {
        const tagList = tags.filter(tag => ids.indexOf(tag.id) > -1);
        if (tagList.length > 0)
            return tagList;
        return [];
    }


    const addToProjectFilters = (projectId) => {
        setFilters(prevState => {
            const newState = {
                ...prevState,
                projects: prevState.projects === undefined ? [projectId] : [projectId, ...prevState.projects]
            }
            return newState;
        })
    }
    const addToTagFilters = (tagId) => {
        setFilters(prevState => {
            const newState = {
                ...prevState,
                tags: prevState.tags === undefined ? [tagId] : [tagId, ...prevState.tags]
            }
            return newState;
        })
    }

    return (
        <Fragment>
            <TaskFilters
                filters={filters}
                setFilters={setFilters}
                projectIdsToProjects={projectIdsToProjects}
                tagIdsToTags={tagIdsToTags}
            />


            <div className="mb-4 flex items-center justify-between">
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    Tasks
                </h5>
                <Button size={"sm"}
                        onClick={()=>setDeleteTasksPopupEnabled(true)}
                        color={"failure"}
                >
                    <HiOutlineTrash className="mr-2 h-4 w-4"/>
                    {"Delete All Tasks"}
                </Button>
            </div>
            <TaskList
                tasks={filteredTasks}
                projectIdsToProjects={projectIdsToProjects}
                tagIdsToTags={tagIdsToTags}
                addToProjectFilters={addToProjectFilters}
                addToTagFilters={addToTagFilters}
            />
            <Modal
                show={deleteTasksPopupEnabled}
                size="md"
                popup={true}
                onClose={()=>setDeleteTasksPopupEnabled(false)}
            >
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Delete all tasks?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button
                                color="failure"
                                onClick={()=>{
                                    setTasks([])
                                    setDeleteTasksPopupEnabled(false)
                                }}
                            >
                                Yes, delete all
                            </Button>
                            <Button
                                color="gray"
                                onClick={()=>setDeleteTasksPopupEnabled(false)}
                            >
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default Tasks;
