import {HiOutlineFolder, HiOutlineHashtag, HiXCircle} from "react-icons/hi";
import {Button} from 'flowbite-react/lib/cjs/components';

const TaskFilters = ({filters, setFilters, projectIdsToProjects, tagIdsToTags}) => {
    const removeFromProjectFilters = (projectId) => {
        setFilters(prevState => {
            const newState = {...prevState, projects: prevState.projects.filter(project => project !== projectId)}
            return newState;
        })
    }

    const removeFromTagFilters = (tagId) => {
        setFilters(prevState => {
            const newState = {...prevState, tags: prevState.tags.filter(tag => tag !== tagId)}
            return newState;
        })
    }

    return (
        <div className="flex flex-wrap gap-2">
            {filters.projects !== undefined && filters.projects.length > 0 &&
                projectIdsToProjects(filters.projects).map(project =>
                    <Button size={"sm"} key={project.id}
                            onClick={() => removeFromProjectFilters(project.id)}
                    >
                        <HiOutlineFolder className="mr-2 h-4 w-4"/>
                        {project.name}
                        <HiXCircle className="ml-2 h-4 w-4"/>
                    </Button>
                )}
            {filters.tags !== undefined && filters.tags.length > 0 &&
                tagIdsToTags(filters.tags).map(tag =>
                    <Button size={"sm"} key={tag.id}
                            onClick={() => removeFromTagFilters(tag.id)}
                    >
                        <HiOutlineHashtag className="mr-2 h-4 w-4"/>
                        {tag.name}
                        <HiXCircle className="ml-2 h-4 w-4"/>
                    </Button>
                )}
        </div>
    )
}

export default TaskFilters