import {useContext} from "react";
import AddTask from "./components/AddTask";
import DataContext from "./dataStorage/DataProvider";
import {Card} from 'flowbite-react/lib/cjs/components';
import Tasks from "./components/Tasks";

function App() {
    const {tasks, setTasks} = useContext(DataContext);
    const handleAddTask = (task) => {
        setTasks(prevState => {
            const newTasks = [task, ...prevState];
            return newTasks;
        })
    }

    return (
        <div>
            {/* Add Task */}
            <div className={"p-5"}>
                <AddTask onTaskSave={handleAddTask}/>
            </div>

            {/*Tasks*/}
            <div className={"p-5"}>
                <Card>
                    <Tasks/>
                </Card>
            </div>

        </div>
    )
}

export default App;
