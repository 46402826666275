const TaskName = ({name, onChange}) => {
    const handleChange = (e) => {
        onChange(e.target.value)
    }
    return (
        <div className="flex items-center border-b border-blue-300 py-2">
            <input className="appearance-none bg-transparent border-none w-full mr-3 leading-tight focus:outline-none
                    text-blue-800 font-bold text-lg py-2 px-2
                    "
                type="text" placeholder="Task Name" aria-label="Task name"
                onChange={handleChange}
                value={name}
            >
            </input>
        </div>
    )
}

export default TaskName