import {Fragment, useContext, useEffect, useState} from "react";
import StartIcon from "../resources/icons/StartIcon";
import StopIcon from "../resources/icons/StopIcon";
import {nanoid} from "nanoid";
import Duration from "./addTask/Duration";
import Tag from "./addTask/Tag";
import Project from "./addTask/Project";
import TaskName from "./addTask/TaskName";
import DataContext from "../dataStorage/DataProvider";
import {HiOutlinePlay, HiOutlineStop} from "react-icons/hi";
import {Tooltip} from 'flowbite-react/lib/cjs/components';

const StartButton = ({onClick}) => {
    return (
        // <button className="text-blue-800 py-2 px-2" onClick={onClick}>
        //     <StartIcon className={"w-8 h-8 text-blue-800 hover:scale-110 hover:text-blue-400"}/>
        // </button>
        <Tooltip content="Start">
            <button className="text-blue-800 py-3 px-3" onClick={onClick}>
                <HiOutlinePlay className="h-8 w-8 hover:scale-125 transition duration-150 ease-in-out"/>
            </button>
        </Tooltip>
    )
}
const StopButton = ({onClick}) => {
    return (
        // <button className="text-blue-800 py-2 px-2" onClick={onClick}>
        //     <StopIcon className={"w-8 h-8 text-blue-800 hover:scale-110 hover:text-blue-400"}/>
        // </button>
        <Tooltip content="Stop">
            <button className="text-blue-800 py-3 px-3" onClick={onClick}>
                <HiOutlineStop className="h-8 w-8 hover:scale-125 transition duration-150 ease-in-out"/>
            </button>
        </Tooltip>
    )
}
const AddTask = ({onTaskSave}) => {
    const {projects, setProjects} = useContext(DataContext)
    const {tags, setTags} = useContext(DataContext)

    const [task, setTask] = useState({
        name: "",
        projectId: "None",
        tagIds: []
    });
    const [isTaskStarted, setIsTaskStarted] = useState(false)
    const [duration, setDuration] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0,
    })

    let timeInternal = null;
    useEffect(() => { //Used to clear if timeInternal is not cleared or not null
        if (isTaskStarted)
            startTimeInternal()
        else
            stopTimeInternal()
        return () => timeInternal !== null && clearInterval(timeInternal);
    }, [isTaskStarted]);

    const startTimeInternal = () => {
        const startDate = new Date();
        startDate.getFullYear();
        setTask(prevState => {
            const newState = {
                ...prevState,
                startDate: {
                    year: startDate.getFullYear(),
                    month: startDate.getMonth(),
                    day: startDate.getDate(),
                },
                startTime: {
                    hours: startDate.getHours(),
                    minutes: startDate.getMinutes(),
                    seconds: startDate.getSeconds(),
                }
            };
            return newState;
        })

        timeInternal !== null && clearInterval(timeInternal);

        timeInternal = setInterval(() => {
            setDuration(prevState => {
                let {hours, minutes, seconds} = {...prevState}
                seconds += 1;
                if (seconds >= 60) {
                    seconds = 0;
                    minutes += 1;
                    if (minutes >= 60) {
                        minutes = 0;
                        hours += 1;
                    }
                }
                return {hours: hours, minutes: minutes, seconds: seconds}
            })
        }, 1000);
    }
    const stopTimeInternal = () => {
        clearInterval(timeInternal)
    }

    const handleTaskStart = () => {
        setIsTaskStarted(true);
    }
    const handleTaskStop = () => {
        setIsTaskStarted(false);
        const taskSaveData = {
            name: task.name,
            projectId: task.projectId,
            tagIds: task.tagIds,
            startDate: task.startDate,
            startTime: task.startTime,
            duration: {...duration}
        }
        onTaskSave(taskSaveData);
        setTask({
            name: "",
            projectId: "None",
            tagIds: []
        })
        setDuration({
            hours: 0,
            minutes: 0,
            seconds: 0,
        })
    }

    const handleAddNewProject = (projectName) => {
        const project = {id: nanoid(3), name: projectName};

        setProjects(prevState => {
            if (prevState !== undefined && prevState !== null) {
                const newState = [...prevState, project];
                return newState;
            }
            return [project];
        })
        setTask(prevState => {
            const newState = {...prevState, projectId: project.id};
            return newState;
        })
    }
    const handleProjectSelect = (projectId) => {
        setTask(prevState => ({...prevState, projectId: projectId})
        )
    }

    const handleAddNewTag = (tagName) => {
        const newTag = {id: nanoid(3), name: tagName};
        setTags(prevState => [...prevState, newTag])
        const newTags = [...task.tagIds, newTag.id]
        setTask(prevState => {
            const newState = {...prevState, tagIds: newTags};
            return newState;
        })
    }
    const handleTagsSelect = (tagIds) => {
        setTask(prevState => ({...prevState, tagIds: tagIds}))
    }
    return (
        <div className={"flex flex-wrap items-center gap-4 justify-center"}>
            <div className={"flex-auto w-72 h-14 bg-gray-50"}>
                <TaskName name={task.name} onChange={(name) => setTask(prevState => {
                    return {...prevState, name: name}
                })}/>
            </div>
            <div className={"flex flex-row"}>
                <div className={"flex-none w-14 h-14 "}>
                    <Project projects={projects} projectId={task.projectId} onAddNewProject={handleAddNewProject}
                             onProjectSelect={handleProjectSelect}/>
                </div>
                <div className={"flex-none w-14 h-14 "}>
                    <Tag tags={tags} tagIds={task.tagIds} onAddNewTag={handleAddNewTag}
                         onTagsSelect={handleTagsSelect}/>
                </div>
                <div className={"flex-none w-28 h-14"}>
                    <Duration className={"w-28 h-14 grid place-content-center"} duration={duration}/>
                </div>
                <div className={"flex-none w-14 h-14 "}>
                    {isTaskStarted ?
                        <StopButton onClick={handleTaskStop}/>
                        :
                        <StartButton onClick={handleTaskStart}/>
                    }
                </div>
            </div>
        </div>
    )
}
export default AddTask