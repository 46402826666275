import TagIcon from "../../resources/icons/TagIcon";
import {Fragment, useEffect, useState} from "react";
import CloseIcon from "../../resources/icons/CloseIcon";
import {HiOutlineTag} from "react-icons/hi";
import {Button, Modal, Tooltip} from 'flowbite-react/lib/cjs/components';

const Tag = ({tags, tagIds, onAddNewTag, onTagsSelect}) => {
    const [popoverEnabled, setPopoverEnabled] = useState(false)
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchText, setSearchText] = useState("");
    useEffect(() => {
        setSelectedTags([...tagIds]);
    }, [tagIds])

    const handleAddNewTag = () => {
        if (searchText === "")
            return;
        onAddNewTag(searchText);
        setSearchText("");
    }
    const handleClosePopup = () => {
        setPopoverEnabled(false);
        onTagsSelect(selectedTags);
        setPopoverEnabled(false);
    }
    return (
        <Fragment>
            {/*<button className="text-blue-800 py-2 px-2" onClick={() => setPopoverEnabled(prevState => !prevState)}>*/}
            {/*    <TagIcon className={"w-8 h-8 text-blue-800 hover:fill-current hover:text-blue-800"}/>*/}
            {/*</button>*/}
            <Tooltip content="Tags" trigger="click">
                <button className="text-blue-800 py-3 px-3" onClick={() => setPopoverEnabled(prevState => !prevState)}>
                    <HiOutlineTag className="h-8 w-8 hover:scale-125 transition duration-150 ease-in-out"/>
                </button>
            </Tooltip>
            {popoverEnabled &&
                <Modal
                    show={popoverEnabled}
                    size="md"
                    popup={true}
                    onClose={() => setPopoverEnabled(false)}
                >
                    <Modal.Body>
                        <div className="p-3 space-y-2">
                            <div className="flex justify-between items-center rounded-t pb-2">
                                <h3 className="font-semibold text-gray-900 dark:text-white">Tags Selection</h3>
                                <button type="button"
                                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                        onClick={handleClosePopup}
                                >
                                    <CloseIcon/>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>

                            <input type="search" id="search-dropdown"
                                   className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                   placeholder="Search"
                                   onChange={(e) => setSearchText(e.target.value)}
                                   value={searchText}
                            />

                            <div className={"grid grid-cols-1 gap-2"}>
                                <div
                                    className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700"
                                    onClick={() => setSelectedTags([])}
                                >
                                    <input checked={selectedTags.length === 0} type="radio" readOnly
                                           name="bordered-radio"
                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"/>
                                    <label
                                        className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">No
                                        Tag</label>
                                </div>

                                <div/>
                                <div/>
                                {tags && tags.length > 0 && tags.map(tag => (
                                    <div key={tag.id}
                                         className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700"
                                         onClick={() => setSelectedTags(prevState => {
                                             if (prevState.indexOf(tag.id) === -1)
                                                 return [...prevState, tag.id];
                                             else
                                                 return prevState.filter(tagId => {
                                                     return tagId !== tag.id
                                                 })
                                         })}
                                    >
                                        <input checked={selectedTags.indexOf(tag.id) > -1} type="checkbox" readOnly
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"/>
                                        <label
                                            className="py-4 ml-2 w-full text-sm font-medium text-gray-900">{tag.name}</label>
                                    </div>
                                ))}

                                <div/>
                                <div className="flex items-center pl-4 rounded border border-gray-200 bg-blue-500"
                                     onClick={handleAddNewTag}
                                >
                                    <label className="py-4 ml-2 w-full text-sm font-medium text-white">New Tag
                                        "{searchText}"</label>
                                </div>
                            </div>

                        </div>
                        <div data-popper-arrow></div>
                    </Modal.Body>
                </Modal>
            }
        </Fragment>
    )
}

export default Tag