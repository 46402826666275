import {createContext, useEffect, useState} from "react";

const DataContext = createContext({})

const fetchLocal = ({key, defaultValue}) => {
    const saved = JSON.parse(localStorage.getItem(key));
    if (saved !== undefined && saved !== null && saved.length > 0)
        return saved;
    return defaultValue;
}
const saveLocal = ({key, value}) => {
    localStorage.setItem(key, JSON.stringify(value));
}
export const DataProvider = ({children}) => {
    const [tasks, setTasks] = useState(fetchLocal({key: "Tasks", defaultValue: []}));
    const [projects, setProjects] = useState(fetchLocal({key: "Projects", defaultValue: []}));
    const [tags, setTags] = useState(fetchLocal({key: "Tags", defaultValue: []}));

    useEffect(() => {
        saveLocal({key: "Tasks", value: tasks});
    }, [tasks])

    useEffect(() => {
        saveLocal({key: "Projects", value: projects});
    }, [projects])

    useEffect(() => {
        saveLocal({key: "Tags", value: tags});
    }, [tags])

    return (
        <DataContext.Provider value={{tasks, setTasks, projects, setProjects, tags, setTags}}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext