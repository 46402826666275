import oneDigitNumberToTwoDigitString from "../../utils/oneDigitNumberToTwoDigitString";
import {Tooltip} from 'flowbite-react/lib/cjs/components';

const Duration = ({duration, className}) => {
    return (
        <Tooltip content="Duration" trigger="click">
            <div className={className}>
            <div className="text-blue-800 font-semibold text-xl
             hover:scale-125 transition duration-150 ease-in-out">
                <label>{duration.hours + ":" + oneDigitNumberToTwoDigitString(duration.minutes) + ":" + oneDigitNumberToTwoDigitString(duration.seconds)}</label>
            </div>
            </div>
        </Tooltip>
    )
}
export default Duration