import {Fragment, useEffect, useState} from "react";
import FolderIcon from "../../resources/icons/FolderIcon";
import CloseIcon from "../../resources/icons/CloseIcon";
import {Tooltip} from 'flowbite-react/lib/cjs/components';
import {HiOutlineExclamationCircle, HiOutlineFolder} from "react-icons/hi";
import {Button, Modal} from 'flowbite-react/lib/cjs/components';

const Project = ({projects, projectId, onAddNewProject, onProjectSelect}) => {
    const [popoverEnabled, setPopoverEnabled] = useState(false)
    const [selectedProject, setSelectedProject] = useState("");
    const [searchText, setSearchText] = useState("");
    useEffect(() => {
        setSelectedProject(projectId);
    }, [projectId])
    // let selectedTags = []
    const handleAddNewProject = () => {
        if (searchText === "")
            return;
        setPopoverEnabled(false);
        setSelectedProject("None");
        onAddNewProject(searchText);
        setSearchText("");
    }
    const handleClosePopup = () => {
        onProjectSelect(selectedProject);
        setPopoverEnabled(false);
    }
    return (
        <Fragment>
            {/*<button className="text-blue-800 py-2 px-2" onClick={() => setPopoverEnabled(prevState => !prevState)}>*/}
            {/*    <FolderIcon className={"w-8 h-8 text-blue-800 hover:fill-current hover:text-blue-800"}/>*/}
            {/*</button>*/}
            <Tooltip content="Project" trigger="click">
                <button className="text-blue-800 py-3 px-3" onClick={() => setPopoverEnabled(prevState => !prevState)}>
                    <HiOutlineFolder className="h-8 w-8 hover:scale-125 transition duration-150 ease-in-out"/>
                </button>
            </Tooltip>
            {popoverEnabled &&
                <Modal
                    show={popoverEnabled}
                    size="md"
                    popup={true}
                    onClose={() => setPopoverEnabled(false)}
                >
                    {/*<div className="text-center py-3">*/}
                    {/*    <h5 className="text-xl font-normal text-gray-900">*/}
                    {/*        Select Project*/}
                    {/*    </h5>*/}
                    {/*</div>*/}
                    <Modal.Body>
                        <div className="p-3 space-y-2">
                            <div className="flex justify-between items-center rounded-t pb-2">
                                <h3 className="font-semibold text-gray-900 dark:text-white">Project Selection</h3>
                                <button type="button"
                                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                        onClick={handleClosePopup}
                                >
                                    <CloseIcon/>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>

                            <input type="search" id="search-dropdown"
                                   className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                   placeholder="Search"
                                   onChange={(e) => setSearchText(e.target.value)}
                            />

                            <div className={"grid grid-cols-1 gap-2"}>
                                <div
                                    className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700"
                                    onClick={() => setSelectedProject("None")}
                                >
                                    <input checked={selectedProject === "None"} type="radio" readOnly
                                           name="bordered-radio"
                                           className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"/>
                                    <label
                                        className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">No
                                        Project</label>
                                </div>

                                <div/>
                                <div/>
                                {projects && projects.length > 0 && projects.map(project => (
                                    <div key={project.id}
                                         className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700"
                                         onClick={() => setSelectedProject(project.id)}
                                    >
                                        <input checked={selectedProject === project.id} type="radio" readOnly
                                               name="bordered-radio"
                                               className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"/>
                                        <label
                                            className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">{project.name}</label>
                                    </div>
                                ))}

                                <div/>
                                <div className="flex items-center pl-4 rounded border border-gray-200 bg-blue-500"
                                     onClick={handleAddNewProject}
                                >
                                    <label className="py-4 ml-2 w-full text-sm font-medium text-white">New Project
                                        "{searchText}"</label>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                // <div role="tooltip"
                //      className="top-20 right-36 inline-block absolute z-10 w-72 text-sm font-light text-gray-500 bg-white rounded-lg border border-gray-200 shadow-sm ">
                //     <div className="p-3 space-y-2">
                //         <div className="flex justify-between items-center rounded-t pb-2">
                //             <h3 className="font-semibold text-gray-900 dark:text-white">Project Selection</h3>
                //             <button type="button"
                //                     className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                //                     onClick={handleClosePopup}
                //             >
                //                 <CloseIcon/>
                //                 <span className="sr-only">Close modal</span>
                //             </button>
                //         </div>
                //
                //         <input type="search" id="search-dropdown"
                //                className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg rounded-l-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                //                placeholder="Search"
                //                onChange={(e) => setSearchText(e.target.value)}
                //         />
                //
                //         <div className={"grid grid-cols-1 gap-2"}>
                //             <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700"
                //                  onClick={() => setSelectedProject("None")}
                //             >
                //                 <input checked={selectedProject === "None"} type="radio" readOnly name="bordered-radio"
                //                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"/>
                //                 <label
                //                     className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">No
                //                     Project</label>
                //             </div>
                //
                //             <div/>
                //             <div/>
                //             {projects && projects.length > 0 && projects.map(project => (
                //                 <div key={project.id}
                //                      className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700"
                //                      onClick={() => setSelectedProject(project.id)}
                //                 >
                //                     <input checked={selectedProject === project.id} type="radio" readOnly
                //                            name="bordered-radio"
                //                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"/>
                //                     <label
                //                         className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">{project.name}</label>
                //                 </div>
                //             ))}
                //
                //             <div/>
                //             <div className="flex items-center pl-4 rounded border border-gray-200 bg-blue-500"
                //                  onClick={handleAddNewProject}
                //             >
                //                 <label className="py-4 ml-2 w-full text-sm font-medium text-white">New Project
                //                     "{searchText}"</label>
                //             </div>
                //         </div>
                //
                //     </div>
                //     <div></div>
                // </div>
            }
        </Fragment>
    )
}

export default Project